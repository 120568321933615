require('jquery');

$(function() {

    const toggleFunction = function (ev) {
        ev.stopPropagation();
        ev.preventDefault();
        let selector = $(this).data('toggle');
        console.log('Changing selector', selector);
        $(selector).toggleClass('hidden');
        const outsideClickListener = (event) => {
            const $target = $(event.target);
            if (!$target.closest(selector).length && !$(selector).hasClass('hidden')) {
                $(selector).addClass('hidden');
                removeClickListener();
            }
        }
        const removeClickListener = () => {
            document.removeEventListener('click', outsideClickListener)
        }
        document.addEventListener('click', outsideClickListener)
    };
    $('.js-dropdown-toggle').click(toggleFunction);
    $('[data-toggle="modal"]').each(function () {
        const target = $($(this).data('target'));
        $(this).click(() => {
            target.toggleClass('hidden');
        });
    });
    $('[data-dismiss="modal"]').each(function () {
        const target = $(this).closest('.modal');
        $(this).click(() => {
            target.toggleClass('hidden');
        });
    });
})