const jQuery = require('jquery');
require('bootstrap');
require('../less/style.less');


window.$ = jQuery;
if (window.TLM) {
    var current = window.TLM.currentPage;
    $(function () {
        if (['catalogo', 'las-naves'].indexOf(current) > -1) {
            current = 'catalago-group';
        }
        $('#navigation ul.nav li.' + current).addClass('active');
    });
}

require('jquery');
require('./common.js');
require('./map.js');


const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function validateEmail(email) {
    return re.test(email);
}


$(function () {
    $('.js-media-open').click(function (e) {
        window.open($(this).find('img').data('big'));
        e.preventDefault();
        return false;
    });

    $('.logoTlm .fondo').click(function () {
        const a = $(this).addClass('shake');
        setTimeout(function () {
            a.removeClass('shake');
        }, 1400);
    });

    var iniPoxY = $('.logoTlm .fondo').css('background-position-y');
    $('#myCarousel').carousel(
        {
            pause: true,
            interval: false
        }
    );


    if ($('.js-puntos-venta').length) {
        loadSellsMap($('.js-puntos-venta'));
    }

    var $frm = $('.js-form-new-payment');
    $frm.off('submit').on('submit', function () {
        var $email = $(this).find('.js-email');
        var email = $email.val();
        var recaptchaResponse = grecaptcha.getResponse();
        if (!recaptchaResponse) {
            alert('Por favor, confirma que no eres un robot');
            return false;
        }
        if (!validateEmail(email)) {
            $email.closest('.control-group').addClass('error');
            $email.focus();
            if (!$('input[name="payment-method"]:checked').length) {
                $('input[name="payment-method"]').closest('.control-group').addClass('error');
            } else {
                $('input[name="payment-method"]').closest('.control-group').removeClass('error');
            }
            return false;
        }
        if (!$('input[name="payment-method"]:checked').length) {
            $('input[name="payment-method"]').closest('.control-group').addClass('error');
            return false;
        }
        $(this).find('.js-pay-btn')
            .addClass('disabled')
            .attr('disabled', 'disabled')
            .html('Connecting...');
        return true;
    });

    $('input[name="payment-method"]').change(function () {
        var val = $(this).val();
        var btn = $('.js-pay-btn .js-replace');
        if (val === 'paypal') {
            btn.html('Pay with PayPal');
        } else if (val === 'mercadopago') {
            btn.html('Pagar con Mercado Pago');
        } else if (val === 'free') {
            btn.html('Continuar');
        }
        $frm.toggleClass('paypal', val === 'paypal')
        $frm.toggleClass('mercadopago', val === 'mercadopago')
    });
    function getVideoId(url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);

        if (match && match[2].length == 11) {
            return match[2];
        } else {
            return 'error';
        }
    }

    $('.js-video-loader').each(function () {
        var videoUrl = $(this).data('url');
        var id = getVideoId(videoUrl);
        if (id === 'error') {
            return;
        }
        var iframeUrl = 'https://www.youtube.com/embed/' + id;
        $(this).find('iframe').attr('src', iframeUrl);
    });

    $('.js-toggle-main-menu').click(function(){
        $('#mainMenu').toggleClass('hidden');
    });

    $('.js-dismiss').click('click', function(){
        $(this).closest('.js-dismiss-target').remove();
    });

});


